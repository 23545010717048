export const downloadFile = (fileName: string, file?: File) => {
  if (file) {
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', fileName)
    link.click()
    URL.revokeObjectURL(fileURL)
  }
}
