import { useTranslation } from 'react-i18next'

import { InvoiceFormat, Permission } from '@sherweb/core/openapi-generated/index.defs'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import { LinkText } from '@sherweb/core/components/LinkText/LinkText'
import { ScrollArea } from '@sherweb/core/components/ScrollArea'
import { Typography } from '@sherweb/core/components/Typography'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { downloadFile } from '@sherweb/core/utils/file/file'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { usePermissionCheck } from '@ssp/modules/authorization'

import { useGetInvoiceDetail } from '../hooks/useGetInvoiceDetail'
import { InvoiceDetailLayout } from './components/InvoiceDetailLayout'
import { InvoiceDetailPaymentMethodContainer } from './components/InvoiceDetailPaymentMethodContainer/InvoiceDetailPaymentMethodContainer'
import { InvoiceDetailSkeleton } from './components/InvoiceDetailSkeleton'
import { usePayInvoiceMutation } from './hooks/usePayInvoiceMutation'

export type InvoiceDetailContentProps = {
  selectedCardId: string | undefined
  setSelectedCardId: (cardId: string | undefined) => void
}

export const InvoiceDetailContent = ({
  selectedCardId,
  setSelectedCardId,
}: InvoiceDetailContentProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { hasAccess } = usePermissionCheck()

  const formatDate = useDateFormatter(language)

  const formatMoneyAmount = useMoneyFormatter(language)

  const invoiceDetail = useGetInvoiceDetail()

  const payInvoiceMutation = usePayInvoiceMutation()

  const handleDownload = async () => {
    await invoiceDetail.downloadInvoiceQuery.refetch().then(result => {
      const fileName = `invoice${invoiceDetail.invoiceDetailsAsyncQuery?.data?.displayInvoiceNumber ?? ''}.pdf`
      downloadFile(fileName, result.data)
    })
  }

  return (
    <ScrollArea className="flex flex-col overflow-y-auto">
      <div className="mx-auto flex max-w-4xl flex-col justify-between p-6">
        {isRequestLoading(invoiceDetail.invoiceDetailsAsyncQuery) ||
        isRequestLoading(invoiceDetail.paymentMethodsByOrganizationIdQuery) ? (
          <InvoiceDetailSkeleton />
        ) : (
          <div className="flex-col">
            <div className="flex flex-col">
              {payInvoiceMutation?.status === 'error' ? (
                <Alert data-testid="alert-error" variant={Variant.Danger} dismissible>
                  {t('ssp:pages.invoices.payment.failureMessage')}
                </Alert>
              ) : null}
              <div className="flex flex-col py-4">
                <Typography variant="heading6" weight="medium" className="mb-4">
                  {t('ssp:pages.invoices.detail.summary')}
                </Typography>
                <InvoiceDetailLayout className="grid grid-cols-12 gap-1 md:gap-8">
                  <InvoiceDetailLayout.Column className="col-span-6">
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.customer')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {invoiceDetail.invoiceDetailsAsyncQuery?.data?.externalClientName}
                      </InvoiceDetailLayout.Content>
                    </div>
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.dueDate')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {formatDate(invoiceDetail.invoiceDetailsAsyncQuery?.data?.dueDate)}
                      </InvoiceDetailLayout.Content>
                    </div>
                    {invoiceDetail.invoiceDetailsAsyncQuery?.data?.invoiceFormats?.includes(
                      InvoiceFormat.Pdf
                    ) && (
                      <div>
                        <InvoiceDetailLayout.Label>
                          {t('ssp:pages.invoices.detail.invoiceDownload')}
                        </InvoiceDetailLayout.Label>
                        <InvoiceDetailLayout.Content>
                          <LinkText to="#" onClick={handleDownload}>
                            {t('ssp:pages.invoices.detail.actions.downloadInvoice')}
                          </LinkText>
                        </InvoiceDetailLayout.Content>
                      </div>
                    )}
                  </InvoiceDetailLayout.Column>
                  <div className="col-span-1" />
                  <InvoiceDetailLayout.Column className="col-span-5">
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.invoiceDate')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {formatDate(invoiceDetail.invoiceDetailsAsyncQuery?.data?.createdOn)}
                      </InvoiceDetailLayout.Content>
                    </div>
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.invoiceTotal')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {formatMoneyAmount(invoiceDetail.invoiceDetailsAsyncQuery?.data?.amountDue)}
                      </InvoiceDetailLayout.Content>
                    </div>
                  </InvoiceDetailLayout.Column>
                </InvoiceDetailLayout>
              </div>
            </div>
            {invoiceDetail.isInvoiceNotPaid && hasAccess(Permission.PayInvoice) ? (
              <div className="flex flex-col gap-4 py-4" data-testid="containerTransaction">
                <Typography variant="heading6" weight="medium" className="md:mb-4">
                  {t('ssp:pages.invoices.detail.transactions')}
                </Typography>
                <InvoiceDetailLayout className="grid grid-cols-12 md:mt-0 md:gap-8">
                  <div className="col-span-12 md:col-span-6 md:mt-0">
                    <InvoiceDetailLayout.Label className="flex">
                      {t('ssp:pages.invoices.detail.paymentMethod')}
                    </InvoiceDetailLayout.Label>
                    <InvoiceDetailPaymentMethodContainer
                      selectedCardId={selectedCardId}
                      setSelectedCardId={setSelectedCardId}
                    />
                  </div>
                  <div className="hidden md:col-span-1 md:block" />
                  <InvoiceDetailLayout.Column className="col-span-5 mt-4 md:mt-0">
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.amountDue')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content variant="heading5" weight="bold">
                        {formatMoneyAmount(
                          invoiceDetail.invoiceDetailsAsyncQuery?.data?.amountRemaining
                        )}
                      </InvoiceDetailLayout.Content>
                    </div>
                  </InvoiceDetailLayout.Column>
                </InvoiceDetailLayout>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </ScrollArea>
  )
}
