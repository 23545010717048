import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import { Error } from '@sherweb/core/components/Error/Error'
import { InfiniteScroll } from '@sherweb/core/components/InfiniteScroll'
import { NoResults } from '@sherweb/core/components/NoResults'
import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { Skeleton } from '@sherweb/core/components/Skeleton'
import { Typography } from '@sherweb/core/components/Typography'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import { useExactMatch } from '@ssp/modules/navigation'
import {
  useGetOrganizationCatalogsSyncQuery,
  useGetProductsFacetsQuery,
  useGetProductsQuery,
} from '@ssp/modules/shop/core/shop.hooks'

import { useGetSearchOptions } from './hooks'
import { ShopAllFilters } from './ShopAllFilters'
import { ShopProductsCard } from './ShopProductsCard'
import { ShopProductsLoader } from './ShopProductsLoader'
import { ShopSearch } from './ShopSearch'
import { ShopSort } from './ShopSort'

export const ShopPage = () => {
  const { t } = useTranslation()

  const searchOptions = useGetSearchOptions()

  const isProductDetailPage = useExactMatch(Routes.ShopProductDetail)

  const productsFacetsQuery = useGetProductsFacetsQuery({
    searchOptions,
    enabled: !isProductDetailPage,
  })

  const productsQuery = useGetProductsQuery({
    searchOptions,
    enabled: !isProductDetailPage && productsFacetsQuery.isSuccess,
  })

  const isProductsQueryLoading = isRequestLoading(productsQuery) || productsQuery.isRefetching

  const organizationCatalogsSyncQuery = useGetOrganizationCatalogsSyncQuery()

  const isProductFacetsLoading = productsFacetsQuery?.isLoading || !productsFacetsQuery?.data

  const hasNoResults =
    !isProductsQueryLoading && productsQuery?.data?.pages?.[0]?.foundProducts === 0

  const isError = productsFacetsQuery.isError || productsQuery.isError

  const handleFetchNextPage = async () => {
    await productsQuery.fetchNextPage()
  }

  const renderResult = () => {
    return hasNoResults ? (
      <NoResults dataTestId="Products" />
    ) : (
      <>
        {productsQuery?.data?.pages?.map(({ products }) =>
          products?.map(product => <ShopProductsCard key={product.id} product={product} />)
        )}
      </>
    )
  }

  const totalProducts = useMemo(() => {
    let totalCount = 0

    productsQuery?.data?.pages?.forEach(page => (totalCount = +page.foundProducts))

    return totalCount
  }, [productsQuery?.data?.pages])

  return (
    <ContainedPage>
      {isError ? (
        <Error />
      ) : (
        <>
          <div className="py-4 sm:py-10">
            <PageTitleWrapper
              className="flex items-center"
              title={<PageTitle data-testid="pageShopTitle">{t('ssp:pages.shop.title')}</PageTitle>}
              subTitle={t('ssp:pages.shop.subTitle')}
            />
            <ShopSearch isLoading={productsQuery?.isLoading} />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex w-full items-center justify-between">
                <Typography variant="heading5" weight="semiBold">
                  {t('ssp:pages.shop.subHeading')}
                </Typography>
              </div>
              <div className="flex w-full">
                <ShopAllFilters
                  facets={productsFacetsQuery?.data}
                  isLoading={isProductFacetsLoading}
                />
              </div>
              <div className="flex items-center justify-between">
                {isProductsQueryLoading ? (
                  <Skeleton className="h-[24px] w-[100px]" />
                ) : (
                  <Typography variant="body2" className="whitespace-nowrap pl-4 md:pl-0 md:pr-4">
                    {t('ssp:pages.shop.productCount', {
                      count: totalProducts,
                    })}
                  </Typography>
                )}
                <ShopSort isLoading={isProductsQueryLoading} />
              </div>
            </div>
            {organizationCatalogsSyncQuery?.data && productsQuery.isSuccess ? (
              <Alert variant={Variant.Warning} className="mt-4">
                {t('ssp:pages.shop.updating')}
              </Alert>
            ) : null}
            <div className="mb-4 grid grid-cols-1 gap-4 lg:gap-6 xl:grid-cols-1">
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
                {isProductsQueryLoading ? <ShopProductsLoader /> : renderResult()}
              </div>
              {productsQuery?.hasNextPage ? (
                <InfiniteScroll
                  isLoading={productsQuery.isFetchingNextPage}
                  hasMore={productsQuery?.hasNextPage}
                  next={handleFetchNextPage}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
      <Outlet />
    </ContainedPage>
  )
}

export default protectPage(ShopPage, Permission.MarketplaceProductList)
