import { DefaultPrivacyLevel } from '@datadog/browser-rum'

import { Language } from '@sherweb/core/common/language'

import CoreEn from './locales/core.en.json'
import CoreFr from './locales/core.fr.json'
import RspEn from './locales/rsp.en.json'
import RspFr from './locales/rsp.fr.json'
import SspEn from './locales/ssp.en.json'
import SspFr from './locales/ssp.fr.json'

const placeholderToReplace = '##PLACEHOLDERTOREPLACEENV##'
const isDefaultEnv = placeholderToReplace.includes('PLACEHOLDERTOREPLACEENV')
const env = process.env.REACT_APP_LOCAL_ENV ?? placeholderToReplace // We use a placeholder that we replace when deploying
const isProduction = env === 'production'
const isDevelopment = ['local', 'development'].includes(env)

const config = {
  env,
  isProduction,
  isDevelopment,
  googleAnalyticsTrackingId:
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ??
    '##PLACEHOLDERTOREPLACEGOOGLEANALYTICSTRACKINGID##',
  appName: 'ssp',
  bff: {
    url: process.env.REACT_APP_LOCAL_BFF ?? '##PLACEHOLDERTOREPLACESSPBFFURL##', // We use a placeholder that we replace when deploying
    signInPath: '/Account/SignIn?portalType=SSP&login_hint=',
    signOutPath: '/Account/SignOut',
    includeCredentials: true,
  },
  i18n: {
    locales: {
      [Language.EN]: { ssp: SspEn, core: CoreEn, rsp: RspEn },
      [Language.FR]: { ssp: SspFr, core: CoreFr, rsp: RspFr },
    },
    defaultLanguage: Language.EN,
    defaultNs: 'ssp',
    fallbackNs: 'core',
  },
  datadog: {
    enabled: !isDefaultEnv,
    applicationId: '97972d51-5596-4be9-9e6f-d49a0b33803a',
    clientToken: 'pubdd8b617b9b6efd8b60d28026d00c79fb',
    site: 'datadoghq.com',
    service: 'ssp.ssp',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
  },
  whiteListed401Paths: ['/login', '/admin/login', '/401', '/admin/401'],
}

export const getSignOutUrl = () => `${config.bff.url}${config.bff.signOutPath}`

export default config
